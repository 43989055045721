import { useStore } from "../../store"
import Cookies from "js-cookie"
import s from "./LanguageSwitcher.module.scss"
import { EnglandFlag, SwedenFlag } from "../common/Icons"

export default function LanguageSwitcher() {
  const [language] = useStore(({ language }) => [language])
  const [setLanguage] = useStore(({ setLanguage }) => [setLanguage])

  // Function to toggle language and update the cookie
  const changeLanguage = () => {
    const newLanguage = language === "sv" ? "en" : "sv"
    setLanguage(newLanguage)
    Cookies.set("language", newLanguage, { expires: 365 }) // Set cookie for 1 year
  }

  return (
    <button className={s.languageSwitcher} onClick={() => changeLanguage()}>
      {language == "sv" ? <EnglandFlag /> : <SwedenFlag />}
    </button>
  )
}
