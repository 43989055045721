import { useState } from "react"
import { useStore } from "../../../store"
import Button from "../../common/Button"
import Modal from "../../common/Modal"
import s from "./Results.module.scss"
import Barometer from "../../common/Barometer"
import { useTranslate } from "../../../lib/localization"

const Results = () => {
  const [totalParking] = useStore(({ totalParking }) => [totalParking])
  const [totalRequired] = useStore(({ totalRequired }) => [totalRequired])
  const [delta] = useStore(({ delta }) => [delta])
  const [totalCo2] = useStore(({ totalCo2 }) => [totalCo2])
  const [totalCost] = useStore(({ totalCost }) => [totalCost])
  const [guestParking] = useStore(({ guestParking }) => [guestParking])
  const [disabledParking] = useStore(({ disabledParking }) => [disabledParking])
  const [showContactModal, setShowContactModal] = useState(false)

  const [language] = useStore(({ language }) => [language])

  const translate = useTranslate()

  const [breakEvenPercent] = useStore(({ breakEvenPercent }) => [
    breakEvenPercent,
  ])

  const co2perParking = totalCo2 / totalParking
  const co2TotalSpan = 7 // Worst case (under mark)
  const co2percentage = 100 - (co2perParking / co2TotalSpan) * 100

  const disclaimer =
    language == "sv"
      ? `
        <p>Beräkningen visar koldioxidutsläpp vid uppförande och baserad på koldioxidekvivalenter från Chalmers Tekniska Högskola, Institutionen för Samhällsbyggnad.</p>
        <h4>Markparkering kan "kosta" mer</h4>
        <p>Det finns indirekta utsläpp av markparkering. Samlad parkering ger ju förhoppningsvis en lägre användning av bilen då den inte ligger i direkt anslutning till byggnaden. Markparkering innebär också ett sämre hushållande med värdefull mark som kan nyttjas bättre med andra positiva miljöeffekter.</p>
  `
      : `
      <p>The calculation shows carbon dioxide emissions during construction, based on carbon dioxide equivalents from Chalmers University of Technology, Department of Civil Engineering.</p>
      <h4>Surface parking may "cost" more</h4>
      <p>There are indirect emissions from surface parking. Shared parking should ideally lead to lower car usage as it is not directly adjacent to the building. Surface parking also results in less efficient use of valuable land, which could be better utilized with other positive environmental impacts.</p>
`

  return (
    <div className={s.container}>
      <div>
        <h2>{translate("Resultat")}</h2>
        <ul className={s.numbers}>
          <li>
            <span>{translate("Total parkering")}</span>{" "}
            <span>{totalParking}</span>
          </li>
          <li>
            <span>{translate("Total parkeringsbehov")}</span>{" "}
            <span>{totalRequired} </span>
          </li>
          {disabledParking && (
            <li>
              <span style={{ marginLeft: "1em", fontSize: "14px" }}>
                {translate("Rörelsehindrade (Antal)")}
              </span>
              <span>{Math.ceil(totalRequired * 0.02)} </span>
            </li>
          )}
          {guestParking && (
            <li>
              <span style={{ marginLeft: "1em", fontSize: "14px" }}>
                {translate("Besöksparkering (Antal)")}
              </span>{" "}
              <span>{Math.ceil(totalRequired / 10)} </span>
            </li>
          )}
          <li>
            <span>
              <b> {translate("Differens")} </b>
            </span>
            <span className={delta >= 0 ? "text-green" : "text-red"}>
              {delta}
            </span>
          </li>
          <li>
            <span>{translate("Total CO2 (Ton)")}</span> <span>{totalCo2} </span>
          </li>
          <li>
            <span>{translate("Total investering (MSEK)")} </span>
            <span>{totalCost} </span>
          </li>

          {totalCo2 >= 0 && (
            <li>
              <Barometer
                title={translate("Klimatpåverkan CO2")}
                percent={co2percentage.toFixed(2) ?? 50}
                disclaimer={translate("Hur beräknar vi klimatpåverkan?")}
                disclaimerText={
                  <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
                }
                items={[
                  {
                    title: translate("Hög påverkan"),
                    color: "#C95A53",
                  },
                  {
                    title: translate("Medium påverkan"),
                    color: "#F2B93C",
                  },
                  {
                    title: translate("Låg påverkan"),
                    color: "#97B988",
                  },
                ]}
              />
            </li>
          )}

          <li>
            <Barometer
              title={translate("Lönsamhet")}
              percent={breakEvenPercent / 2}
              items={[
                {
                  title: translate("Låg lönsamhet"),
                  color: "#C95A53",
                },
                {
                  title: translate("Break-even"),
                  color: "#F2B93C",
                },
                {
                  title: translate("Hög lönsamhet"),
                  color: "#97B988",
                },
              ]}
            />
          </li>
        </ul>
      </div>

      <Button
        style={{ marginTop: "1em" }}
        onClick={() => setShowContactModal(true)}
      >
        {translate("Kontakta oss")}
      </Button>

      <Modal
        title={translate("Kontakta oss")}
        show={showContactModal}
        onHide={() => setShowContactModal(false)}
      >
        <p>
          <b>
            {translate("Har du några frågor eller vill veta mer om Parkyria?")}
          </b>
        </p>
        <p>
          {translate("Kontakta oss")} via{" "}
          <a href="mailto:adetoun@urbanivation.com">adetoun@urbanivation.com</a>
        </p>
      </Modal>
    </div>
  )
}

export default Results
