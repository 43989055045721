import { useMemo, useState } from "react"
import { useStore } from "../../store"
import Accordion from "../common/Accordion"
import Input from "../common/Input"
import Tooltip from "../common/Tooltip"
import { useTranslate } from "../../lib/localization"

const Parking = ({ project, onProjectChange, editDisabled }) => {
  const [pricePerParking, setPricePerParking] = useState(0)
  const [costOfCapital, setCostOfCapital] = useState(7)

  const translate = useTranslate()

  const [setTotalParking] = useStore(({ setTotalParking }) => [setTotalParking])
  const [setTotalRequired] = useStore(({ setTotalRequired }) => [
    setTotalRequired,
  ])

  const [breakEvenPercent, setBreakEvenPercent] = useStore(
    ({ breakEvenPercent, setBreakEvenPercent }) => [
      breakEvenPercent,
      setBreakEvenPercent,
    ],
  )

  const [setTotalCo2] = useStore(({ setTotalCo2 }) => [setTotalCo2])
  const [setTotalCost] = useStore(({ setTotalCost }) => [setTotalCost])
  const [setGuestParking] = useStore(({ setGuestParking }) => [setGuestParking])
  const [setDisabledParking] = useStore(({ setDisabledParking }) => [
    setDisabledParking,
  ])
  const [setDelta] = useStore(({ setDelta }) => [setDelta])
  const parking = project.parking
  const disabledParking = project.disabledParking
  const guestParking = project.guestParking
  const totalParking = parking.reduce((acc, entry) => acc + entry.value, 0)
  const totalCo2 = parking.reduce((acc, entry) => acc + entry.co2, 0)
  const totalLoadAfterReduction = project.existingLoadReductionPercentage
    ? Number(
        project.existingLoad *
          (1 - project.existingLoadReductionPercentage / 100),
      )
    : 0

  // const totalRequired = Math.ceil(
  //   project.construction.reduce((acc, entry) => {
  //     const selectedReduction = project.reductions.find(
  //       (reduction) =>
  //         reduction.selected === true && reduction.type === entry.type,
  //     )
  //     const requiredAfterReduction =
  //       selectedReduction != null
  //         ? entry.required * (1 - selectedReduction.reduction)
  //         : entry.required
  //     return acc + requiredAfterReduction
  //   }, 0) + totalLoadAfterReduction,
  // )

  const totalRequired = Math.ceil(
    project.construction.reduce((acc, entry) => {
      // Get all selected reductions for the current type
      const applicableReductions = project.reductions.filter(
        (reduction) =>
          reduction.selected === true && reduction.type === entry.type,
      )

      // Calculate the total reduction factor
      const totalReductionFactor = applicableReductions.reduce(
        (acc, reduction) => acc * (1 - reduction.reduction),
        1, // Start with a multiplier of 1
      )

      // Apply the total reduction factor
      const requiredAfterReduction = entry.required * totalReductionFactor

      // Accumulate the total
      return acc + requiredAfterReduction
    }, 0) + totalLoadAfterReduction,
  )

  const delta = Math.floor(totalParking - totalRequired)
  const totalCost = parking.reduce((acc, entry) => acc + entry.cost, 0)
  const totalArea = parking.reduce((acc, entry) => acc + entry.area, 0)

  setTotalParking(totalParking)
  setGuestParking(guestParking)
  setTotalRequired(totalRequired)
  setDisabledParking(disabledParking)
  setDelta(delta)
  setTotalCo2(totalCo2.toFixed(0))
  setTotalCost(totalCost.toFixed(0))

  const handleCostChange = (parkingIndex, e) => {
    const inputValue = Number(e.target.value)
    const entry = parking[parkingIndex]
    const newEntry = {
      ...entry,
      costPerParking: inputValue,
      cost: entry.value * inputValue,
    }

    const newParking = parking.map((entry, index) => {
      if (index == parkingIndex) return newEntry

      return entry
    })

    const newProject = {
      ...project,
      parking: newParking,
    }

    onProjectChange(newProject)
  }

  const handleDisableChange = (e) => {
    setDisabledParking(e.target.checked)
    const newProject = {
      ...project,
      disabledParking: e.target.checked,
    }

    onProjectChange(newProject)
  }
  const handleGuestChange = (e) => {
    setGuestParking(e.target.checked)
    const newProject = {
      ...project,
      guestParking: e.target.checked,
    }

    onProjectChange(newProject)
  }

  const handleInputChange = (parkingIndex, type, e) => {
    const inputValue = Number(e.target.value)
    const entry = parking[parkingIndex]

    // If the value is negative or NaN, set it to 0
    if (isNaN(inputValue) || inputValue < 0) {
      inputValue = 0
    }

    const newEntry = {
      ...entry,
      value: inputValue,
      cost: entry.costPerParking * inputValue,
      area: entry.areaPerParking * inputValue,
      co2: entry.co2PerParking * inputValue,
    }

    const newProject = {
      ...project,
      parking: parking.map((entry, index) => {
        if (index == parkingIndex) return newEntry

        return entry
      }),
    }

    onProjectChange(newProject)
  }

  // Calculate break even
  const YEARS = 25 // Number of years for break-even calculation
  const { totalParkingPrice, isBreakEven } = useMemo(() => {
    const parkingPrice = (totalCost * 1000000) / totalParking / YEARS / 12

    const totalParkingPrice = parkingPrice * (1 + costOfCapital / 100)

    setBreakEvenPercent((pricePerParking / totalParkingPrice) * 100)

    const isBreakEven = pricePerParking >= totalParkingPrice

    return { totalParkingPrice, breakEvenPercent, isBreakEven }
  }, [totalCost, totalParking, pricePerParking, costOfCapital])

  return (
    <>
      <div className="toolbar-box">
        <Accordion
          expanded={false}
          title={translate("Inställningar för parkeringskostnader")}
        >
          <table className="toolbar-table">
            <thead>
              <tr>
                <th>
                  <div className="subtitle">{translate("Byggnation")}</div>
                </th>
                <th>
                  <div className="subtitle">{translate("Kostnad / p")}</div>
                </th>
                <th>
                  <div className="subtitle">{translate("Yta / p")}</div>
                </th>
                <th>
                  <div className="subtitle">{translate("Ton C02 / p")}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {parking.map(
                (
                  { type, desc, costPerParking, areaPerParking, co2PerParking },
                  parkingIndex,
                ) => (
                  <tr key={type}>
                    <td>
                      <Tooltip
                        text={"Här kan du lägga till info"}
                        xPosition="left"
                        yPosition="top"
                        showIcon
                      >
                        {translate(desc)}
                      </Tooltip>
                    </td>
                    <td>
                      <Input
                        isDisabled={editDisabled}
                        type="number"
                        className="input-parking"
                        min="0"
                        value={costPerParking === "" ? "" : costPerParking}
                        placeholder={0}
                        onChange={(e) => handleCostChange(parkingIndex, e)}
                        label="MSEK / p"
                        invertColor
                        innerLabel
                        transparentBackground
                      />
                    </td>
                    <td>{areaPerParking} M2 / p</td>
                    <td>{co2PerParking} Ton CO2 / p</td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </Accordion>
      </div>
      <div className="toolbar-box">
        <h4>{translate("Parkeringar")}</h4>
        <table className="toolbar-table">
          <thead>
            <tr>
              <th>
                <div className="subtitle">{translate("Byggnation")}</div>
              </th>
              <th>
                <div className="subtitle">{translate("Input")}</div>
              </th>
              <th>
                <div className="subtitle">{translate("Kostnad")}</div>
              </th>
              <th>
                <div className="subtitle">{translate("Yta")}</div>
              </th>
              <th>
                <div className="subtitle">{translate("Ton C02")}</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {parking.map(
              ({ type, desc, value, cost, area, co2, unit }, parkingIndex) => (
                <tr key={parkingIndex}>
                  <td>
                    <Tooltip
                      text={"Här kan du lägga till info"}
                      xPosition="left"
                      yPosition="top"
                      showIcon
                    >
                      {translate(desc)}
                    </Tooltip>
                  </td>
                  <td>
                    <Input
                      isDisabled={editDisabled}
                      type="number"
                      className="input-parking"
                      min="0"
                      value={value === 0 ? "" : value}
                      placeholder={0}
                      onChange={(e) => handleInputChange(parkingIndex, type, e)}
                      label={translate(unit)}
                      invertColor
                      innerLabel
                      transparentBackground
                    />
                  </td>
                  <td className="td-fixed-width">{cost.toFixed(1)} MSEK</td>
                  <td className="td-fixed-width">{area.toFixed(0)} M2</td>
                  <td className="td-fixed-width">{co2.toFixed(0)} Ton</td>
                </tr>
              ),
            )}
            <tr>
              <td>{translate("Parkering för rörelsehindrade")} (2%)</td>
              <td>
                <Input
                  type="checkbox"
                  checked={disabledParking}
                  onChange={handleDisableChange}
                  transparentBackground
                  isDisabled={editDisabled}
                />
              </td>
            </tr>
            <tr>
              <td>{translate("Besöksparkering")} (10%)</td>
              <td>
                <Input
                  type="checkbox"
                  checked={guestParking}
                  onChange={handleGuestChange}
                  transparentBackground
                  isDisabled={editDisabled}
                />
              </td>
            </tr>
            <tr className="is-totals">
              <td>{translate("Total parkering")}</td>
              <td>{totalParking}</td>
              <td>{totalCost.toFixed(1)}</td>
              <td>{totalArea.toFixed(0)}</td>
              <td>{totalCo2.toFixed(0)}</td>
            </tr>
            <tr className="is-totals">
              <td>{translate("Total parkeringsbehov")}</td>
              <td>{totalRequired}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            {disabledParking && (
              <tr className="is-totals">
                <td>
                  <span style={{ marginLeft: "1em" }}>
                    {translate("För rörelsehindrade")}
                  </span>
                </td>
                <td>{Math.ceil(totalRequired * 0.02)}</td>
                <td></td>
                <td></td>
                <td> </td>
              </tr>
            )}
            {guestParking && (
              <tr className="is-totals">
                <td>
                  <span style={{ marginLeft: "1em" }}>
                    {translate("Besöksparkering")}
                  </span>
                </td>
                <td>{Math.ceil(totalRequired / 10)}</td>
                <td></td>
                <td></td>
                <td> </td>
              </tr>
            )}
            <tr className="is-totals fw-bold">
              <td className={delta.toFixed(0) >= 0 ? "text-green" : "text-red"}>
                {translate("Differens")}
              </td>
              <td className={delta.toFixed(0) >= 0 ? "text-green" : "text-red"}>
                {delta.toFixed(0)}
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="toolbar-box">
        <h4>
          {translate("Räkna ut intäkter från parkeringar för break-even")}
        </h4>
        <table className="toolbar-table">
          <thead>
            <tr>
              <th>
                <div className="subtitle">
                  {translate("Ange intäkt per parkering")}
                </div>
              </th>
              <th>
                <div className="subtitle">
                  {translate("Ange kapitalkostnad")}
                </div>
              </th>
              <th>
                <div className="subtitle">
                  {translate("Behövs för break-even")}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <td>
              <Input
                isDisabled={editDisabled}
                type="number"
                className=""
                min="0"
                value={pricePerParking}
                placeholder={0}
                onChange={(e) => setPricePerParking(e.target.value)}
                label={translate("Per/mån")}
                invertColor
                innerLabel
                transparentBackground
              />
            </td>
            <td>
              <Input
                isDisabled={editDisabled}
                type="number"
                className=""
                min="0"
                max="100"
                value={costOfCapital}
                placeholder={0}
                onChange={(e) => setCostOfCapital(e.target.value)}
                label="%"
                invertColor
                innerLabel
                transparentBackground
              />
            </td>
            <td>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>{totalParkingPrice.toFixed(0)}</span>
                {isBreakEven ? (
                  <div style={{ color: "var(--green)" }}>
                    (+{(breakEvenPercent - 100).toFixed(0)}%)
                  </div>
                ) : (
                  <div style={{ color: "var(--red)" }}>
                    (-{(100 - breakEvenPercent).toFixed(0)}%)
                  </div>
                )}
              </div>
            </td>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Parking
