import { useStore } from "../store"

////////////////////////////////////////////////////////
/// Är "Parkings" korrekt på engelska för "Parkeringar"
///////////////////////////////////////////////////////

// Define translation strings
const translations = {
  "Mitt konto": {
    en: "My account",
  },
  Projekt: {
    en: "Project",
  },

  "Mina projekt": {
    en: "My projects",
  },

  "Nytt projekt": {
    en: "New project",
  },

  "Skapa nytt projekt": {
    en: "Create new project",
  },

  "Skapa projekt": {
    en: "Create project",
  },
  Bilpool: {
    en: "Car pool",
  },

  "Logga ut": {
    en: "Sign out",
  },

  Anteckningar: {
    en: "Notes",
  },

  "Skriv dina anteckningar...": {
    en: "Type in your notes here...",
  },

  Resultat: {
    en: "Results",
  },

  "Total parkering": {
    en: "Total parking",
  },

  "Total parkeringsbehov": {
    en: "Total parking demand",
  },

  Differens: {
    en: "Difference",
  },

  "Total investering (MSEK)": {
    en: "Total investment (MSEK)",
  },

  "Klimatpåverkan CO2": {
    en: "Climate impact CO2",
  },

  "Hög påverkan": {
    en: "High impact",
  },

  "Medium påverkan": {
    en: "Medium impact",
  },

  "Låg påverkan": {
    en: "Low impact",
  },

  Lönsamhet: {
    en: "Profitability",
  },

  "Hög lönsamhet": {
    en: "High profitability",
  },
  "Break-even": {
    en: "Break-even",
  },
  "Låg lönsamhet": {
    en: "Low profitability",
  },

  "Hur beräknar vi klimatpåverkan?": {
    en: "How do we calculate the environmental impact?",
  },

  "Rörelsehindrade (Antal)": {
    en: "Disabled (Number)",
  },

  "Besöksparkering (Antal)": {
    en: "Visitor parking (Number)",
  },

  Flerbostadshus: {
    en: "Apartment building",
  },

  Studentbostäder: {
    en: "Student housing",
  },

  Vårdbostäder: {
    en: "Nursing homes",
  },

  Kontor: {
    en: "Office",
  },
  Lager: {
    en: "Warehouse",
  },
  Sjukhus: {
    en: "Hospital",
  },
  Universitet: {
    en: "University",
  },
  Skola: {
    en: "School",
  },
  Förskola: {
    en: "Preschool",
  },
  Handel: {
    en: "Retail",
  },
  "Småhus gemensam P": {
    en: "Detached houses shared parking",
  },
  "Småhus tomtparkering": {
    en: "Detached houses on-site parking",
  },
  Hotell: {
    en: "Hotel",
  },

  "kvm BTA": {
    en: "sqm BTA",
  },

  Lägenhet: {
    en: "Apartment",
  },

  Rum: {
    en: "Room",
  },

  Mobilitetsåtgärder: {
    en: "Mobility management",
  },

  Mobilitetsåtgärd: {
    en: "Mobility management",
  },

  Byggnation: {
    en: "Construction",
  },

  "P-norm": {
    en: "Parking standard",
  },

  Reduktion: {
    en: "Reduction",
  },

  "Reduktion befintlig belastning": {
    en: "Reduction of existing load",
  },

  Select: {
    en: "Select",
  },

  "Total (Brutto)": {
    en: "Total (Net)",
  },

  Egenskap: {
    en: "Property",
  },

  Värde: {
    en: "Value",
  },

  Kommun: {
    en: "Municipality",
  },

  Zon: {
    en: "Zone",
  },

  Beskrivning: {
    en: "Description",
  },

  "Befintliga bilplatser som ska lösas i projektet": {
    en: "Existing parking spaces to be resolved in the project",
  },

  Visa: {
    en: "Show",
  },

  Dölj: {
    en: "Hide",
  },

  "Inställningar för parkeringskostnader": {
    en: "Settings for parking costs",
  },

  Parkeringar: {
    en: "Parkings",
  },

  parkeringar: {
    en: "parkings",
  },

  "Räkna ut intäkter från parkeringar för break-even": {
    en: "Calculate parking revenue for break-even",
  },

  "Ange intäkt per parkering": {
    en: "Enter revenue per parking",
  },

  "Ange kapitalkostnad": {
    en: "Enter capital cost",
  },

  "Behövs för break-even": {
    en: "Required for break-even",
  },

  "Per/mån": {
    en: "Per/month",
  },

  "Kopiera länk": {
    en: "Copy link",
  },

  "Ta bort projekt": {
    en: "Delete project",
  },

  "Ta bort": {
    en: "Delete",
  },

  Avbryt: {
    en: "Cancel",
  },

  "Kopiera projekt": {
    en: "Duplicate project",
  },

  Kopiera: {
    en: "Duplicate",
  },

  "Länken har kopierats!": {
    en: "Link copied!",
  },

  "Det gick inte att kopiera länken, försök igen": {
    en: "Failed to copy the link, please try again",
  },

  "Är du säker på att du vill ta bort detta projekt?": {
    en: "Are you sure you want to delete this project?",
  },

  "Vill du kopiera detta projekt? Projektet kommer hamna i din lista över projekt.":
    {
      en: "Do you want to duplicate this project? The project will be added to your list of projects.",
    },

  Kostnad: {
    en: "Cost",
  },

  Yta: {
    en: "Area",
  },

  "Kostnad / p": {
    en: "Cost / p",
  },

  "Yta / p": {
    en: "Area / p",
  },

  Besöksparkering: {
    en: "Visitor parking",
  },

  "Parkering för rörelsehindrade": {
    en: "Parking for disabled",
  },

  "Parkering för rörelsehindrade": {
    en: "Parking for disabled",
  },

  "För rörelsehindrade": {
    en: "For disabled",
  },

  "Total parkering": {
    en: "Total parking",
  },

  "Total parkeringsbehov": {
    en: "Total parking demand",
  },

  "Bilpool med pköp": {
    en: "Carpool with parking purchase",
  },

  "Bilfritt boende": {
    en: "Car-free housing",
  },

  "Grön resplan": {
    en: "Green travel plan",
  },

  "Behov innan reduktion": {
    en: "Demand before reduction",
  },

  "Behov efter reduktion": {
    en: "Demand after reduction",
  },

  Skillnad: {
    en: "Difference",
  },

  "Kryssa i om projektet har flerbostadshus och är inom någon av dessa zoner": {
    en: "Check if the project includes apartment buildings and is within any of these zones.",
  },

  "Inom 500m från spårväg": {
    en: "Within 500m from tramway",
  },

  "Bibehållen parkering": {
    en: "Retained parking",
  },
  "P-hus över mark": {
    en: "Parking garage above-ground",
  },

  "P-garage under mark": {
    en: "Underground parking garage",
  },

  Mark: {
    en: "Ground",
  },

  "P-köp": {
    en: "Parking purchase",
  },

  "Logga in": {
    en: "Sign in",
  },

  "Logga ut": {
    en: "Sign out",
  },

  "Kontakta oss": {
    en: "Contact us",
  },

  "Har du några frågor eller vill veta mer om Parkyria?": {
    en: "Do you have questions or want to know more about Parkyria?",
  },

  "Kontakta oss via": {
    en: "Contact us via",
  },

  "Välkommen tillbaka!": {
    en: "Welcome back!",
  },

  "Registrera konto": {
    en: "Register account",
  },

  "E-post": {
    en: "E-mail",
  },

  Lösenord: {
    en: "Password",
  },

  "Ange e-post": {
    en: "Enter e-mail",
  },

  "Ange lösenord": {
    en: "Enter password",
  },

  "Har du inget konto?": {
    en: "No account yet?",
  },

  "Vill du testa Parkyria?": {
    en: "Do you want to try Parkyria?",
  },

  "Kontakta oss så hjälper vi dig att komma igång!": {
    en: "Contact us and we'll help you get started.",
  },

  "Du har angivit ett felaktigt användarnamn eller lösenord": {
    en: "You have entered an incorrect username or password.",
  },

  Fastighetsbeteckning: {
    en: "Property designation",
  },

  "T.ex Lund Posthornet 1": {
    en: "Example: Lund Posthornet 1",
  },

  "Fastighetsbeteckningen måste starta med 'Lund'.": {
    en: "The property designation needs to start with 'Lund'.",
  },

  "Begäran om planbesked": {
    en: "Request for a planning decision",
  },

  Sparar: {
    en: "Saving",
  },
}

export const useTranslate = () => {
  const locale = useStore((state) => state.language) // Reactively watch `language`

  const translateString = (word) => {
    if (translations.hasOwnProperty(word) && translations[word][locale]) {
      return translations[word][locale]
    }
    return word
  }

  return translateString
}
