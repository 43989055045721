import { useState } from "react"
import { useNavigate } from "react-router-dom"
import AuthService from "../AuthService"
import Input from "../common/Input"
import Button from "../common/Button"
import s from "./Login.module.scss"
import { Link } from "react-router-dom"
import Register from "../Register"
import cn from "clsx"
import Message from "../common/Message"
import { useTranslate } from "../../lib/localization"
import LanguageSwitcher from "../LanguageSwitcher"
import { useStore } from "../../store"
import { LoadingSpinner } from "../common/Icons"

// URL to access register form = login?register=true

const Login = ({ setLoggedIn }) => {
  const translate = useTranslate()

  const [language] = useStore(({ language }) => [language])

  // Check if URL contains register and display register form
  const queryParameters = new URLSearchParams(window.location.search)
  const isRegister = queryParameters.get("register") === "true"

  const [showRegister, setShowRegister] = useState(isRegister)
  const [loading, setLoading] = useState(false)

  const [credentials, setCredentials] = useState({ email: "", password: "" })
  const [error, setError] = useState("")
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()

    setLoading(true)
    try {
      await AuthService.login(credentials)
      // await AuthService.verifyToken()
      // Redirect to home on successful verification
      setLoggedIn(AuthService.getToken())
      navigate("/")
    } catch (error) {
      console.log("error!", error)

      setLoading(false)

      setError(
        translate("Du har angivit ett felaktigt användarnamn eller lösenord"),
      )
    }
  }

  const onChange = (type, e) => {
    setCredentials((old) => ({
      ...old,
      [type]: e.target.value,
    }))
  }

  return (
    <div className={s.container}>
      <div className={s.wrap}>
        <div className={s.form}>
          <div className={s.languageSwitcher}>
            <LanguageSwitcher />
            {language == "sv" && "Change to English"}
          </div>
          <div className={s.navigation}>
            <ul>
              <li>
                <button onClick={() => setShowRegister(false)}>
                  {translate("Logga in")}
                </button>
              </li>
              <li>
                <button onClick={() => setShowRegister(true)}>
                  {translate("Registrera konto")}
                </button>
              </li>
            </ul>
            <div className={cn(s.activeMarker, showRegister && s.isActive)} />
          </div>
          {showRegister ? (
            <>
              {isRegister ? (
                <Register setShowRegister={setShowRegister} animate />
              ) : (
                <div>
                  <Message style={{ backgroundColor: "#fff" }}>
                    <div>
                      <h4>{translate("Vill du testa Parkyria?")}</h4>
                      {translate(
                        "Kontakta oss så hjälper vi dig att komma igång!",
                      )}
                      <a
                        href="mailto:adetoun@urbanivation.com"
                        style={{ marginTop: "1.5em", display: "block" }}
                      >
                        <Button>{translate("Kontakta oss")}</Button>
                      </a>
                    </div>
                  </Message>
                </div>
              )}
            </>
          ) : (
            <>
              <h1 className={s.title}>{translate("Välkommen tillbaka!")}</h1>
              <form onSubmit={handleSubmit}>
                <div className="form-grid form-grid--large-gutter">
                  <div>
                    <Input
                      type="email"
                      onChange={(e) => onChange("email", e)}
                      value={credentials.username}
                      placeholder={translate("Ange e-post")}
                      label={translate("E-post")}
                      showLabel
                      size="large"
                    />
                  </div>
                  <div>
                    <Input
                      type="password"
                      onChange={(e) => onChange("password", e)}
                      value={credentials.password}
                      placeholder={translate("Ange lösenord")}
                      label={translate("Lösenord")}
                      showLabel
                      size="large"
                    />
                  </div>
                  <Button type="submit" size="large">
                    {loading ? <LoadingSpinner /> : translate("Logga in")}
                  </Button>

                  {error && <Message type="error">{error}</Message>}

                  <div className={s.register}>
                    {translate("Har du inget konto?")}
                    <button onClick={() => setShowRegister(true)}>
                      {translate("Registrera konto")}
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </div>

      <div className={s.image}>
        <img
          src="/login-image.jpg"
          width="1280"
          height="1440"
          loading="lazy"
          alt="Login bild"
        />
      </div>
    </div>
  )
}

export default Login
