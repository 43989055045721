import cn from "clsx"
import Accordion from "../../common/Accordion"
import Input from "../../common/Input"
import Tooltip from "../../common/Tooltip"
import s from "./Construction.module.scss"
import React from "react"
import { MdDirectionsBike, MdDirectionsCar } from "react-icons/md"
import { useTranslate } from "../../../lib/localization"

export const Totals = ({ construction }) => {
  const activeUnits = construction
    .map((entry) => entry.unit)
    .filter((value, index, self) => self.indexOf(value) === index)
    .filter((unit) =>
      construction.find((entry) => entry.unit === unit && entry.value > 0),
    )

  return (
    <>
      {activeUnits.map((unit, i) => (
        <Total construction={construction} unit={unit} key={i} />
      ))}
      <Total construction={construction} isBrutto={true} />
    </>
  )
}

const Total = ({ construction, unit, isBrutto }) => {
  const translate = useTranslate()

  let filtered = []

  if (unit)
    filtered = construction.filter(
      (entry) => entry.unit.toLowerCase() === unit.toLowerCase(),
    )
  else filtered = construction

  const total = filtered.reduce((acc, entry) => acc + entry.value, 0)
  const totalRequired = filtered.reduce((acc, entry) => acc + entry.required, 0)

  const lcFirst = function (string) {
    if (string == null) {
      return string
    }

    if (string.length === 0) {
      return string
    }

    return string[0].toLowerCase() + string.slice(1)
  }

  return (
    <tr className="is-totals">
      {isBrutto ? (
        <td>{translate("Total (Brutto)")}</td>
      ) : (
        <td>
          Total{" "}
          {unit === "Lägenhet"
            ? lcFirst(translate(unit)) + "er"
            : lcFirst(translate(unit))}
        </td>
      )}
      <td></td>
      <td>
        {unit && total}{" "}
        {unit === "Lägenhet" && total > 1
          ? lcFirst(translate(unit)) + "er"
          : lcFirst(translate(unit))}
      </td>
      <td>{totalRequired.toFixed(1)}</td>
    </tr>
  )
}

const ConstructionTable = ({
  project,
  zone,
  unit,
  handleConstructionChange,
  handleWithinCityWallChange,
  handleCloseToTrain,
  editDisabled,
}) => {
  const construction = project.construction

  const translate = useTranslate()

  if (project.zone == "Övriga staden") {
    if (project.closeToPublicTrain) {
      construction[0].density = 8
      construction[0].required = (construction[0].value * 8) / 1000
    } else {
      construction[0].density = 9
      construction[0].required = (construction[0].value * 9) / 1000
    }
  }

  return (
    <>
      <table className="toolbar-table">
        <thead>
          <tr>
            <th>
              <div className="subtitle">
                <Tooltip text="Lorem ipsum dolor sit amet" showIcon>
                  {translate("Byggnation")}
                </Tooltip>
              </div>
            </th>
            <th>
              <div className="subtitle">{translate("P-norm")}</div>
            </th>
            <th>{project.propertyIdenfifier}</th>
            <th>
              <div className="subtitle">
                <MdDirectionsCar />
              </div>
            </th>
            {/* <th>
              <div className="subtitle">
                <MdDirectionsBike />
              </div>
            </th> */}
          </tr>
        </thead>
        <tbody>
          {construction.map(
            (
              {
                type,
                desc,
                value,
                policy,
                unit,
                required,
                // requiredBike,
                closeToPublicTrain,
                density,
                buildingAreaMeasurement,
              },
              constructionIndex,
            ) => {
              const unitTranslated = translate(unit)

              const selectedReduction = project.reductions.find(
                (reduction) =>
                  reduction.selected === true && reduction.type === type,
              )

              return (
                <React.Fragment key={constructionIndex}>
                  <tr>
                    <>
                      <td>
                        <Tooltip
                          text={
                            desc == "Studentbostäder"
                              ? "Detta är info om studentbostäder"
                              : desc == "Vårdbostäder"
                                ? "Detta är info om vårdbostäder"
                                : desc == "Kontor"
                                  ? "Detta är info om kontor"
                                  : "Detta är info"
                          }
                          xPosition="left"
                          yPosition="top"
                          showIcon
                        >
                          {translate(desc)}
                        </Tooltip>
                      </td>

                      <td>
                        {density} /{" "}
                        {buildingAreaMeasurement === 1
                          ? ""
                          : buildingAreaMeasurement}{" "}
                        {unitTranslated}
                      </td>
                      <td>
                        <Input
                          type="number"
                          isDisabled={editDisabled}
                          min={0}
                          step={unit === "kvm BTA" ? 500 : 1}
                          className="input-number"
                          // value={construction[constructionIndex].value}
                          value={
                            construction[constructionIndex].value === 0
                              ? null
                              : construction[constructionIndex].value
                          }
                          placeholder={0}
                          onChange={(e) =>
                            handleConstructionChange(constructionIndex, e)
                          }
                          innerLabel
                          invertColor
                          transparentBackground
                          label={unitTranslated}
                        />
                      </td>
                      <td
                        className={cn(
                          selectedReduction ? "selectedReduction" : "",
                          "highlighted",
                        )}
                      >
                        {required.toFixed(1)}
                      </td>
                      {/* <td className="highlighted" style={{ opacity: 0.5 }}>
                        0.0
                      </td> */}
                    </>
                  </tr>
                  {desc == "Flerbostadshus" &&
                  construction[constructionIndex].value &&
                  project.zone == "Övriga staden" ? (
                    <tr className={s.infoWrapper}>
                      <td colSpan="4" className={s.infoText}>
                        <p>
                          {translate(
                            "Kryssa i om projektet har flerbostadshus och är inom någon av dessa zoner",
                          )}
                          :
                        </p>
                        <div className={s.flex}>
                          <p style={{ marginRight: "1em" }}>
                            {translate("Inom 500m från spårväg")}
                          </p>
                          <Input
                            type="checkbox"
                            checked={project.closeToPublicTrain}
                            onChange={(e) => handleCloseToTrain(e)}
                            transparentBackground
                          />
                        </div>
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              )
            },
          )}
          <Totals construction={construction} />
        </tbody>
      </table>
    </>
  )
}

const Construction = ({ zone, project, onProjectChange, editDisabled }) => {
  const translate = useTranslate()

  const handleWithinCityWallChange = (e) => {
    const newProject = {
      ...project,
      withinCityWall: e.target.checked,
    }

    onProjectChange(newProject)
  }

  const handleCloseToTrain = (e) => {
    // // Konstruera en uppdaterad version av 'construction'-arrayen
    // const updatedConstruction = e.target.checked
    //   ? [
    //       {
    //         ...project.construction[0],
    //         density: 8, // Uppdaterar 'density' till 8 om 'closeToTrain' är true
    //       },
    //       ...project.construction.slice(1),
    //     ]
    //   : project.construction // Använd original 'construction' om 'closeToTrain' är false

    // Skapa ett nytt projektobjekt med den uppdaterade 'construction'-arrayen och 'closeToTrain'-värdet
    const newProject = {
      ...project,
      // construction: updatedConstruction,
      closeToPublicTrain: e.target.checked,
    }

    // Anropa callback-funktionen med det nya projektobjektet
    onProjectChange(newProject)
  }

  const handleExistingLoadChange = (e) => {
    const newProject = {
      ...project,
      existingLoad: e.target.value,
    }

    onProjectChange(newProject)
  }

  const handleConstructionChange = (constructionIndex, e) => {
    const construction = project.construction
    const entry = construction[constructionIndex]
    const value = Number(e.target.value)

    const newEntry = {
      ...entry,
      value: value,
      required: (value * entry.density) / entry.buildingAreaMeasurement,
      // requiredBike: (value * entry.density) / entry.buildingAreaMeasurement,
    }

    const newProject = {
      ...project,
      construction: construction.map((entry, index) => {
        if (index == constructionIndex) {
          return newEntry
        }
        return entry
      }),
    }

    onProjectChange(newProject)

    return
  }

  return (
    <div className="toolbar-box">
      <Accordion title={translate("Byggnation")}>
        <p style={{ marginBottom: "2px" }}>
          {translate("Befintliga bilplatser som ska lösas i projektet")}
        </p>
        <Input
          type="number"
          isDisabled={editDisabled}
          min={0}
          step={1}
          className="input-number"
          value={project.existingLoad}
          onChange={(e) => handleExistingLoadChange(e)}
          innerLabel
          invertColor
          transparentBackground
          style={{ marginBottom: "1em", width: "200px" }}
        />

        <ConstructionTable
          editDisabled={editDisabled}
          project={project}
          zone={zone}
          handleConstructionChange={handleConstructionChange}
          handleWithinCityWallChange={handleWithinCityWallChange}
          handleCloseToTrain={handleCloseToTrain}
        />
      </Accordion>
    </div>
  )
}
export default Construction
