import s from "./ProjectDetails.module.scss"
import { MdEdit, MdCheck } from "react-icons/md"
import Input from "../../common/Input"
import { useState } from "react"
import { useTranslate } from "../../../lib/localization"

const ProjectDetails = ({ project, onProjectChange }) => {
  const { propertyIdentifier, description, commune, zone } = project
  const [isEditable, setIsEditable] = useState(false)
  const [desc, setDesc] = useState(description)

  const translate = useTranslate()

  const toggleEdit = () => {
    if (isEditable && desc !== description) {
      handleEdit()
    }
    setIsEditable(!isEditable)
  }

  const handleEdit = () => {
    onProjectChange({ ...project, description: desc })
  }

  const handleDescriptionChange = (e) => {
    setDesc(e.target.value)
  }

  return (
    <div className="toolbar-box">
      <div className={s.headcontainer}>
        <h4 style={{ display: "inline" }} className="">
          {translate("Projekt")}
        </h4>
        <button
          className={
            isEditable ? "btn btn-sm btn-success" : "btn btn-sm btn-light"
          }
          onClick={toggleEdit}
        >
          {isEditable ? <MdCheck /> : <MdEdit />}
        </button>
      </div>
      <div className={s.container}>
        <div>
          <div className="subtitle">{translate("Egenskap")}</div>
          <div>Fastighet</div>
        </div>

        <div>
          <div className="subtitle">{translate("Värde")}</div>
          <div>{propertyIdentifier}</div>
        </div>

        <div>
          <div className="subtitle">{translate("Kommun")}</div>
          <div>{commune}</div>
        </div>

        <div>
          <div className="subtitle">{translate("Zon")}</div>
          <div>{zone}</div>
        </div>

        <div>
          <div
            style={{ display: "inline", paddingRight: "4px" }}
            className="subtitle"
          >
            {translate("Beskrivning")}
          </div>
          <div>
            {isEditable ? (
              <Input
                type="text"
                className="input-text"
                invertColor
                transparentBackground
                placeholder={translate("Beskrivning")}
                value={desc}
                onChange={(e) => handleDescriptionChange(e)}
              ></Input>
            ) : (
              <div>{desc}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetails
