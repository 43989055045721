import React, { useState } from "react"
import { Form } from "react-bootstrap"
import axios from "axios"
import Button from "./common/Button"
import Modal from "./common/Modal"
import Input from "./common/Input"
import Message from "./common/Message"
import { MdAdd } from "react-icons/md"
import { toast } from "react-toastify"
import { useTranslate } from "../lib/localization"

function CreateProjectModal({ onClose }) {
  const [show, setShow] = useState(false)
  const [propertyIdentifier, setPropertyIdentifier] = useState("")
  const [description, setDescription] = useState("")
  const [error, setError] = useState("")

  const translate = useTranslate()

  const handleClose = () => {
    setShow(false)
    setPropertyIdentifier("") // Reset the project name on modal close
    setDescription("") // Reset the project name on modal close
    setError("") // Reset the error on modal close
    onClose()
  }

  const handleShow = () => setShow(true)

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      // Send a POST request to the server
      const response = await axios.post(
        `${process.env.REACT_APP_PARKYRIA_BACKEND_URL}/api/projects`,
        {
          propertyIdentifier,
          description,
        },
      )

      handleClose() // Close the modal after submission

      toast.success(translate("Projektet har skapats!"))
    } catch (error) {
      console.error("Error submitting the project:", error.response.data)
      setError(translate("Fastighetsbeteckningen måste starta med 'Lund'."))
    }
  }

  const handlePropertyIdChange = (e) => {
    setPropertyIdentifier(e.target.value)
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value)
  }

  return (
    <>
      <Button icon={<MdAdd />} onClick={handleShow}>
        {translate("Nytt projekt")}
      </Button>

      <Modal
        title={translate("Skapa nytt projekt")}
        show={show}
        onHide={handleClose}
        buttons={[
          <Button onClick={handleSubmit} size="large">
            {translate("Skapa nytt projekt")}
          </Button>,
        ]}
      >
        <form onSubmit={handleSubmit}>
          <div className="form-grid">
            {!error ? (
              <Message type="success">
                {translate("Fastighetsbeteckningen måste starta med 'Lund'.")}
              </Message>
            ) : (
              <Message type="error">{error}</Message>
            )}

            <Input
              type="text"
              placeholder={translate("T.ex Lund Posthornet 1")}
              value={propertyIdentifier}
              label={translate("Fastighetsbeteckning")}
              showLabel
              onChange={handlePropertyIdChange}
              size="large"
            />
            <Input
              type="text"
              placeholder={translate("Begäran om planbesked")}
              value={description}
              label={translate("Beskrivning")}
              showLabel
              onChange={handleDescriptionChange}
              size="large"
            />
          </div>
        </form>
      </Modal>
    </>
  )
}

export default CreateProjectModal
